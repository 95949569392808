@media (max-width: 1200px) {
   .hero h1 {
      font-size: 7vw;
   }
   .hero {
      height: 90%;
   }
   .header {
      height: 100%;
   }
   .text1,
   .text2,
   .text3 {
      position: absolute;
      /* opacity: 0; */
   }
   .other {
      height: 40%;
      display: flex;
   }
   .words-container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex: 1;
   }
   .texts-container {
      display: flex;
      justify-content: center;
      width: 100%;
   }
   .dev-container {
      display: flex;
      justify-content: center;
      margin-left: 70%;
      width: 100%;
   }
   .nav {
      color: white;
      width: 100%;
      height: 10%;
      display: flex;
      /* justify-content: center;
      align-items: center; */
      margin-top: 10px;
   }
   .logo {
      width: 100%;
      height: 100%;
   }
   .logo-container {
      left: 0;
      cursor: pointer;
   }
   .logo-container:hover {
      color: #ed0026;
      text-shadow: 0px 0px 5px #ed0026;
   }
}
